import { match } from 'ts-pattern';

import iconPlaceholder from '../../assets/img/placeholder/category.png';
import { apiService } from '../../services/api-service';
import { type Tag, TaggedObjectType, virtualTagList } from '../../types';
import { fromMediaDTO } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { MediaUtils, type PickMediaUrlOptions } from '../../utils/media';

export class TagUtils {
  static async GetTagByIdOrSlug(idOrSlug: string): Promise<Tag | null> {
    const virtualTag = virtualTagList.find((t) => t.slug === idOrSlug);
    if (virtualTag) {
      return virtualTag;
    }
    const resp = await apiService.tag.getTagBySlug(idOrSlug);
    return resp.data.tag;
  }

  static IsVirtualTag(tagSlug: string): boolean {
    return virtualTagList.some((t) => t.slug === tagSlug);
  }

  static GetObjectsCount(tag: Tag, objectType: TaggedObjectType): number {
    return (
      tag.extensions?.find((e) => e.objectType === objectType)?.objectsCount ||
      0
    );
  }

  static GetObjectLabel(
    objectType: TaggedObjectType,
    withPrimePrefix?: boolean
  ): string {
    return match(objectType)
      .with(TaggedObjectType.Block, () => 'Block')
      .with(TaggedObjectType.Game, () => 'Game')
      .with(
        TaggedObjectType.PrimeGame,
        () => `${withPrimePrefix ? 'Prime ' : ''}Game`
      )
      .with(TaggedObjectType.GamePack, () => 'Game Pack')
      .with(
        TaggedObjectType.PrimeGamePack,
        () => `${withPrimePrefix ? 'Prime ' : ''}Game Pack`
      )
      .with(TaggedObjectType.Brand, () => 'Brand')
      .with(TaggedObjectType.ProgramRound, () => 'Program Round')
      .otherwise(() => 'Unknown');
  }

  static PickBackgroundURL(
    tag?: Tag | null,
    options?: PickMediaUrlOptions
  ): string {
    return (
      MediaUtils.PickMediaUrl(fromMediaDTO(tag?.background?.media), options) ||
      getStaticAssetPath('images/bg-game-tag-2024-02.png')
    );
  }

  static PickIconURL(tag?: Tag | null, options?: PickMediaUrlOptions) {
    return (
      MediaUtils.PickMediaUrl(fromMediaDTO(tag?.icon?.media), options) ||
      iconPlaceholder
    );
  }
}
